import React from "react";
import theme from "theme";
import { Theme, Text, Link, Strong, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms-and-conditions"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section padding="64px 0" sm-padding="40px 0" color="--light" font="--base">
			<Override slot="SectionContent" color="--primary" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
				Terms and Conditions
			</Text>
			<Text margin="0px 0px 60px 0px">
				Welcome to Bos Balance ("Bos Balance", "we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our website located at{" "}
				<Link href="https://bosbalance.com" color="--secondary">
					bosbalance.com
				</Link>
				{" "}("Website") and the services provided by us ("Services"). By accessing or using our Website and Services, you agree to comply with and be bound by these Terms.
			</Text>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 20px"
				list-style-type="decimal"
				as="ol"
				display="grid"
				grid-gap="16px"
			>
				<Text margin="0px 0px 0px 0px">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Acceptance of Terms
					</Strong>
					<br />
					By using our Website and Services, you accept these Terms in full. If you disagree with any part of these Terms, you must not use our Website or Services. The applicability of any terms and conditions of the customer is explicitly rejected.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Services
					</Strong>
					<br />
					We offer a range of accounting services, including but not limited to bookkeeping, tax preparation, financial consulting, and payroll management. Specific terms for each service may be outlined in separate agreements.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						User Conduct
					</Strong>
					<br />
					You agree to use our services solely for lawful purposes and in accordance with these Terms. You may not use our services in any way that violates applicable laws or regulations or infringes upon the rights of others.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Privacy Policy
					</Strong>
					<br />
					Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using our services, you consent to the terms of our Privacy Policy, which can be found here.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Client Responsibilities
					</Strong>
					<br />
					You agree to provide accurate, complete, and timely information necessary for us to perform the Services. You agree to provide accurate, complete, and timely information, documents, and records necessary for us to perform the Services. You must vouch for the accuracy and completeness of the information provided, even if this information comes from third parties. We are under no circumstances liable for damage caused by the client not meeting the above-mentioned requirements. The rights and obligations for the client resulting from the agreement with us and/or these Terms are not transferable.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Fees and Payment
					</Strong>
					<br />
					{"\n"}All fees for our Services will be outlined in a separate agreement or proposal. Payment terms will be specified in these documents. Failure to pay for Services may result in suspension or termination of your account and access to our Services.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Confidentiality
					</Strong>
					<br />
					We are committed to maintaining the confidentiality of your information. We will not disclose your information to any third parties without your consent, except as required by law. For more details, please refer to our Privacy Policy.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Intellectual Property
						<br />
					</Strong>
					All content, logos, trademarks, and other intellectual property on our Website are owned by us or our licensors. You may not use any content from our Website without our prior written permission.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Limitation of Liability
						<br />
					</Strong>
					To the fullest extent permitted by law, we will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of our Services or these Terms. Our total liability to you for any damages, losses, and causes of action will not exceed the amount paid by you for the Services.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Indemnification
					</Strong>
					<br />
					You agree to indemnify, defend, and hold harmless Bos Balance, its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in connection with your use of our Services or violation of these Terms.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Termination
					</Strong>
					<br />
					We may terminate or suspend our Services at any time, without prior notice or liability, if you breach these Terms or for any other reason. Upon termination, your right to use the Services will immediately cease.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Governing Law
					</Strong>
					<br />
					These Terms are governed by and construed in accordance with the laws of The Netherlands. Any dispute arising out of or in connection with these Terms or your use of our Services will be exclusively resolved by the appropriate Dutch courts.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Changes to Terms
					</Strong>
					<br />
					We reserve the right to modify or replace these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting on our Website. Your continued use of our Website and Services following the posting of revised Terms means that you accept and agree to the changes.{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Contact Us
					</Strong>
					<br />
					If you have any questions or concerns about these Terms or our services, please contact us at support@bosbalance.com.{" "}
				</Text>
			</List>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});